import React, { Fragment, useEffect } from "react";
import Markup from "./markup/markup";

// bootstrap
import "bootstrap/dist/css/bootstrap.min.css";

// slick-carousel
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// React Modal Video
import "react-modal-video/css/modal-video.min.css";

import "swiper/swiper-bundle.min.css";

// Fonts
import "./vendors/fontawesome/css/font-awesome.min.css";
import "./vendors/flaticon/flaticon.css";
import "./vendors/flaticon/light/flaticon1.css";
import "./vendors/line-awesome/css/line-awesome.css";
import "./vendors/themify/themify-icons.css";

// Stylesheet
import "./css/typography.css";
import "./css/shortcodes/shortcodes.css";
import "./css/style.css";
import "./css/color/color-1.css";
import "./App.css";
import { Accessibility } from 'accessibility';
import { useTranslation } from "react-i18next";

const enLabels = {
  menuTitle: 'Accessibility Menu',
  increaseText: 'Increase text size',
  decreaseText: 'Decrease text size',
  increaseTextSpacing: 'Increase text spacing',
  decreaseTextSpacing: 'Decrease text spacing',
  invertColors: 'Invert colors',
  grayHues: 'Gray hues',
  underlineLinks: 'Underline links',
  bigCursor: 'Big cursor',
  readingGuide: 'Reading guide',
  textToSpeech: 'Text to speech',
  speechToText: 'Speech to text',
  disableAnimations: 'Disable animations'
};

const heLabels = {
  menuTitle: 'נגישות',
  increaseText: 'להגדיל את גודל הטקסט',
  decreaseText: 'להקטין את גודל הטקסט',
  increaseTextSpacing: 'להגדיל את מרווח הטקסט',
  decreaseTextSpacing: 'להקטין את מרווח הטקסט',
  invertColors: 'הפוך צבעים',
  grayHues: 'גוונים אפורים',
  underlineLinks: 'קו תחתון קישורים',
  bigCursor: 'סמן גדול',
  readingGuide: 'מדריך קריאה',
  textToSpeech: 'טקסט לדיבור',
  speechToText: 'דיבור לטקסט',
  disableAnimations: 'להשבית אנימציות'
};

const icon = {
  position: {
    bottom: { size: 15, units: 'px' },
    left: { size: 25, units: 'px' },
    type: 'fixed'
  },
  dimensions: {
    width: { size: 40, units: 'px' },
    height: { size: 40, units: 'px'},
  },
  backgroundColor: "#26d103",
  img: ['accessible'],
}

const App = () => {
  const { t, i18n } = useTranslation();
  
  useEffect(() => {
    let styleEles = document.getElementsByClassName("_access-main-css");
    let iconEles = document.getElementsByClassName("_access-icon material-icons _access");
    let divEles = document.getElementsByClassName("_access-menu _access bottom type left");

    if (styleEles && styleEles.length > 0
      && iconEles && iconEles.length > 0
      && divEles && divEles.length > 0) {
      styleEles[0].remove();
      iconEles[0].remove();
      divEles[0].remove();
      if (i18n.language === "en") {
        let options = {
          labels: enLabels,
          icon: icon
        };
        options.textToSpeechLang = i18n.language; 
        options.speechToTextLang = i18n.language; 
        new Accessibility(options);
      }
      else {
        let options = {
          labels: heLabels,
          icon: icon
        };
        options.textToSpeechLang = i18n.language; 
        options.speechToTextLang = i18n.language; 
        new Accessibility(options);
      }
    }
  }, [i18n.language])

  useEffect(() => {
    let options = {
      labels: i18n.language === "en" ? enLabels : heLabels,
      icon: icon
    };
    options.textToSpeechLang = i18n.language; 
    options.speechToTextLang = i18n.language; 
    new Accessibility(options);
  }, [])

  return (
    <Fragment>
      <Markup />
    </Fragment>
  );
};

export default App;
