import React, { Component, lazy } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";

// Elements
const BackToTop = lazy(() => import("./elements/back-top"));
const About3 = lazy(() => import("./pages/about-3"));
const Error404 = lazy(() => import("./pages/error-404"));
const GetInTouch = lazy(() => import("./pages/get-in-touch"));
const MyAccount = lazy(() => import("./pages/my-account"));
const BecomePartner = lazy(() => import("./pages/projects-single-1"));
const Aerospace = lazy(() => import("./pages/aerospace"));
const Medical = lazy(() => import("./pages/medical"));
const OilGas = lazy(() => import("./pages/oilgas"));
const CivilInfrastructures = lazy(() => import("./pages/civil"));
const RenewableEnergey = lazy(() => import("./pages/renewableenergey"));
const CPartsManagement = lazy(() => import("./pages/cpartsmanagement"));
const KanBan = lazy(() => import("./pages/kanban"));
const Calculator = lazy(() => import("./pages/calculator"));
const Quality = lazy(() => import("./pages/quality"));
const ScrollToTop = lazy(() => import("../scrollToTop"));
class Markup extends Component {
  render() {
    return (
      <ToastProvider placement="bottom-left">
        <BrowserRouter basename={"/"}>
          <ScrollToTop />
          <Switch>
            <Route path="/" exact component={About3} />
            <Route path="/error-404" component={Error404} />
            <Route path="/get-in-touch" component={GetInTouch} />
            <Route path="/my-account" component={MyAccount} />
            <Route path="/becomeapartner" component={BecomePartner} />
            <Route path="/cpartsmanagement" component={CPartsManagement} />
            <Route path="/aerospace" component={Aerospace} />
            <Route path="/medical" component={Medical} />
            {/* <Route path="/defense" component={Defense} /> */}
            <Route path="/oilgas" component={OilGas} />
            <Route
              path="/civil-infrastructures"
              component={CivilInfrastructures}
            />
            <Route path="/renewable-energey" component={RenewableEnergey} />
            <Route path="/kanban" component={KanBan} />
            <Route path="/calculator" component={Calculator} />
            <Route path="/quality" component={Quality} />
          </Switch>
        </BrowserRouter>
        <BackToTop />
      </ToastProvider>
    );
  }
}

export default Markup;
